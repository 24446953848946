import { GlobalStyles } from '@hospy/hospy-ui';
import {
	ChatwootProvider,
	HospyThemeProvider,
	SessionMonitoring,
	TranslateProvider,
	ValidateAuth
} from '@hospy/shared-components';
import { store } from '@hospy/store';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import ErrorBoundaryFallback from 'libs/hospy-ui/src/lib/organisms/error-boundary-fallback';
import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import './base-theme.css';
import App from './components/app/app';
import FeedbackEnableCookies from 'libs/hospy-ui/src/lib/organisms/feedback-enable-cookies';
//import { IntercomProvider } from 'react-use-intercom';

Sentry.init({
	dsn: 'https://b864bbd4b1134175adfcfe1b7192702e@o4504636114927616.ingest.sentry.io/4504669510893568',
	tracesSampleRate: 1.0,
	// This sets the sample rate to be 10%. You may want this to be 100% while
	// in development and sample at a lower rate in production
	replaysSessionSampleRate: 0.1,
	// If the entire session is not sampled, use the below sample rate to sample
	// sessions when an error occurs.
	replaysOnErrorSampleRate: 1.0,
	integrations: [
		new BrowserTracing()
		//new Sentry.Replay()
	],
	environment: process.env['NODE_ENV'] || 'development'
});

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

root.render(
	<StrictMode>
		<Provider store={store}>
			<GlobalStyles />
			<FeedbackEnableCookies>
				<ValidateAuth>
					<BrowserRouter>
						<SessionMonitoring />						
						<HospyThemeProvider>
							<TranslateProvider
								app="start"
								defaultMessagesFunction={
									import(
										'./translations/compiled-lang/es.json'
									)
								}
							>
								<Sentry.ErrorBoundary
									fallback={(props) => (
										<ErrorBoundaryFallback {...props} />
									)}
								>
									<ChatwootProvider
									>
										<App />
									</ChatwootProvider>
								</Sentry.ErrorBoundary>
							</TranslateProvider>
						</HospyThemeProvider>
					</BrowserRouter>
				</ValidateAuth>
			</FeedbackEnableCookies>
		</Provider>
	</StrictMode>
);