import { LoadingAppView } from '@hospy/shared-components';
import { setAppName, useAppDispatch, useAppSelector } from '@hospy/store';
import AppRoute from '@start/components/routes/app-route';

import { AnimatePresence } from 'framer-motion';
import React, { useEffect } from 'react';

const App = () => {
	const { isAuth, user } = useAppSelector(({ user }) => user);

	const dispatch = useAppDispatch();

	useEffect(() => {
		dispatch(setAppName('START'));
	}, []);

	if (isAuth && !user) return <LoadingAppView />;

	return (
		<React.Suspense fallback={<LoadingAppView />}>
			<AnimatePresence>
				<AppRoute />
			</AnimatePresence>
		</React.Suspense>
	);
};

export default App;
